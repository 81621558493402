
import { defineComponent,PropType } from "vue";
import * as echarts from "echarts/core";

import { CanvasRenderer } from "echarts/renderers";
// 使用するチャート 折れ線グラフ
import { LineChart} from "echarts/charts";
// 使用するコンポーネント
import {
  TitleComponent,
  LegendComponent,
  GridComponent,
//  ToolboxComponent,
  DataZoomComponent,
  BrushComponent,
} from "echarts/components";

import moment from "moment";

echarts.use([
  // レンダリングエンジン Canvas経由
  CanvasRenderer,
  // 使用するチャート 折れ線グラフ
  LineChart,
  // コンポーネント 題名をつける
  TitleComponent,
  // コンポーネント レジェンド
  LegendComponent,
  // コンポーネント グリッド
  GridComponent,
  //ツールボックス コンポーネント
  // ToolboxComponent,
  //ズーム コンポーネント
  DataZoomComponent,
  //
  BrushComponent,
]);

import echart from "vue-echarts";

//const getSampleDataUrl= "https://iotcore-inte-api.azurewebsites.net/api/sensors/10100039?code=YTcZLcMx94idOwluN3Ou0uzm7J5ygB2Uc5gz7sDNOBHAPWu4hBuEmQ==";

export type ChartData = {
    series?:Object,
    xAxis?:Object,
    yAxis?:Object
 };

export default defineComponent({
  name: "Chart",
  components: { 'echart':echart },
  props:{ chartData : Object as PropType<ChartData> },
  data() {

    return {
         initOptions:{renderer:"svg"},
      option:{
         grid: {
          top: '40%', // グラフの表示エリアの上部マージン
        },
        // コンポーネント ツールチップ
        tooltip: { trigger: "axis" },
        legend:{
          padding: [0,0,0,0],
          itemGap:10,
          pageButtonPosition:"start",
          top: 'top'
        },
        toolbox:{
          show:"true",
          feature:{
            magicType:{
              show:"true",
              type:["bar","line"]
            },
            brush:{ type:["lineX","clear"] }
          }
        },
        brush:{
          xAxisIndex:"all",
          brushLink:"all",
          outOfBrush: { colorAlpha: 0 }
        },
          dataZoom: [//グラフズーム設定
                    {
                    type: "inside",
                    realtime: "true",
                    filterMode : "none",//ズームした時の描画範囲外につなげるか否か
                    minValueSpan : 100,
                    },
                    {
                    show : "true",
                    type : "slider",
                    realtime : "true",
                    minValueSpan :100,
                     bottom: '2%',
                    // labelFormatter: (value,valueStr) => this.callNextData(value,valueStr)
                    labelFormatter: (value:any,valueStr:any) => valueStr.replace(" ","\n")
                    }
                ],
        xAxis: {},
        yAxis: {},
        series:[]
      }
    }
  } ,

  mounted : function(this:{ reload:any }){
    this.reload()
  },
  methods: {
    onReady(instance:any,o:any) {
        console.log(instance);
      },
      onClick(event:any, instance:any,o:any) {
        console.log(arguments);
      },
    // グラフ表示生成
    reload( this: { option : any , chartData:ChartData }){
      this.option.series = this.chartData.series;
      
      this.option.xAxis = this.chartData.xAxis;
      this.option.yAxis = this.chartData.yAxis;
      
   /*   console.log(echart.getDataURL({
	    pixelRatio: 2,
	    backgroundColor: '#fff'
        }));
     */ /*
        echart.setOption({ series: this.chartData.series});
      echart.setOption({ xAxis: this.chartData.xAxis});
      echart.setOption({ yAxis: this.chartData.yAxis});      
   
     */ 
    }
  }
});


