<template>
<div @click.self="hideMenus()" >
<br>
     
     

<div class="menu" style="text-align:right;">
<a v-on:click="showFile" class="show-menu" style="cursor:pointer; ">  <font-awesome-icon :icon="faFile" style="margin-left:8px;"  size="lg" /> 設定   </a>

</div>
<br>
<div v-show="isShowFile"  class="show-menu bg-light offset-7 col-4"  style="z-index:99999; text-align:left; padding: 10px; position: absolute;">

  <b style="font-size:17px;">ファイル</b>
  
<hr>
     <div class="show-menu input-group-sm input-group-sm mb-3">
     <a v-on:click="downloadImage" style=" margin-right:1%; margin-botton:1%; cursor:pointer;"> 画像に出力する</a>
    </div>  
<hr>
    <div class="show-menu input-group-sm input-group-sm mb-3">
    <a class="show-menu" v-on:click="showCSVFiles=!showCSVFiles" style="margin-right:1%; margin-botton:1%; cursor:pointer; "> CSVのダウンロード </a>
        <div class="show-menu bg-light" v-show="showCSVFiles" style="position: absolute;left: -140px; width: 220px; z-index:1;">
            <ul class="show-menu" style="list-style: none; padding:10px;">
            <li v-show="v.name" v-for=" v , i in chartData.series" :key="v.name" >
               <a class="show-menu" v-on:click="downloadCsv(i)"  style="margin-right:1%; margin-botton:1%; cursor:pointer;" >{{ v.name }} </a>
               <hr>
            </li>
            </ul>
       </div>
    <br>
    </div>
  <hr>
  <!--
  <b style="font-size:17px;">レポート</b>
  
  <hr>
     <div class="show-menu input-group-sm input-group-sm mb-3">
     <a v-on:click="copyURL" style="margin-right:1%; margin-botton:1%; cursor:pointer;">URLをコピーする - preview</a>
  </div>
<hr>
-->
  <b style="font-size:17px;">高度な機能 </b>
  <hr>
     <div class="show-menu input-group-sm input-group-sm mb-3">
     <a v-on:click="showSQL" style="margin-right:1%; margin-botton:1%; cursor:pointer;">SQL インプット - preview</a>
  </div>
  <hr>
      <div class=" show-menu input-group input-group-sm mb-3">
       <span class="show-menu input-group-text" id="inputGroupFileAddon01">CSVのインポート <br> - preview </span>
   
       
  <input  v-on:change="importCsv" type="file" id="inputGroupFile01" class="show-menu form-control" aria-describedby="inputGroupFileAddon01">
  </div>
  
 </div>

       <br>
      <component v-bind:is="currentView"  v-bind:chartData="chartData" v-bind:sqlResults="sqlResults"  ></component>
      
    <br>
     

    <div v-show="isShowSQL">
    <div  class="col-12" v-for="(input ,index) in editorList" :key="index">
          <hr>
        
     <div style="height:200px;">
     
        <MonacoEditor 
       :key="index" 
       :id="'editor-'+index"
        class="editor" 
        ref="editor"
        :options="editorList[index]"
        v-model="editorList[index].value"
        language="sql" 
        @change="editorDidChange"
       >

       </MonacoEditor>
     
     </div>
    </div>
    <!--
    <br>
        <span style=" font-size:24px; cursor:pointer;" v-on:click="addSqlField" > + </span>
    <br>
    -->
    <input class="btn btn-dark favorite styled" type="button" value="SQLを実行する" v-on:click="executeSql">
    </div>
  
</div>

</template>

<script>

import MonacoEditor from 'monaco-editor-vue3'
import Chart from '../../components/chart/Chart.vue'

import {  faFile ,faChartArea } from "@fortawesome/free-solid-svg-icons";

const { Parser }  = require( "json2csv");
const _ = require('lodash');
const Compression = require('../../modules/app/compression'); 
import moment from "moment";

import { Sql } from "../../modules/app/sql";

export default {
  name: 'LineChartView',
  components: {
    Chart,
    MonacoEditor
  },
  data(){
    return{
                sq : true , 
     showCSVFiles :false ,
     isEditMode : false,
     particle_size : "",
     aggregate : "", 
     start_date  :"",
     end_date :"",
     Chart : Chart ,
     currentView: Chart , 
     isShowFile :false ,
     isShowView: false, 
     isShowSQL: true,
     faFile:faFile,
     faChartArea: faChartArea , 
     Sql : new Sql(),
     executeList : [] ,
     search: { nodeID:""},
      editorList : [  ] ,
      sqlResults: [] ,
      chartData : {
           xAxis: {
            type: 'category',
            data: []
            },
        yAxis: {
            type: 'value'
        },
        series: [
         {
            name : "",
            data: [],
            type: 'line',
            smooth: true,
            connectNulls: true
        }
        ]       
    },
    }
  },
  async beforeUnmount(){
    
       window.removeEventListener('message', this.outsideClickEvent);    
  },
  async created  (){
      this.isChartPage = window.location.pathname === '/chart/';
   
    // Check if the current page is not within an iframe


      var param = new URLSearchParams(window.location.search);
      this.isEditMode = (param.get('iFrameId') )? false:true ;

      if (this.$route.query.c){

          var p = await Compression.Compression.decompressString( this.$route.query.c );
          console.log(p);
          param = Compression.Compression.parseQueryString(p);
// sensorIDs
      }
         this.$emit('show-loader',true);   
          
          var sql =  (this.$route.query.c) ? param.get("q") :this.$route.query.q;
          
          //for(;;){
            try{
              
              sql = window.decodeURIComponent (sql) ;
            }catch (e) {  console.warn(e); }
            
            try{
              
              sql = window.decodeURIComponent (sql) ;
            }catch (e) {  console.warn(e); }
          //}
           
      
          this.editorList[0] = {value: ( sql != "undefined" )? sql:""  } ;
                   
               try{
         this.sq =  ! ( param.get('sq') == "0") ;

         }catch (e){ 
          
          console.warn(e);
        }

        this.start_date = param.get('start') ;
        this.end_date = param.get('end');       
        this.particle_size  = param.get('particle_size') ;
        this.aggregate  = param.get('agg') ;
       
      if ( ! window.isCrossOrigin ){
      
      this.Sql = await  window.parent.getSql() ;   
      
      } else {
        this.Sql = await  window.getSql() ;
      }
  },
        mounted: async function(){
        /*
        if(  window.self === window.top ){
         
                this.isShowSQL=false;
                window.document.addEventListener('click',this.outsideClickEvent );
                  this.$emit('show-loader',false);  
                  await new Promise(s => setTimeout(s, 200));
                  await this.executeSql(); 
                   this.$emit('show-loader',false);
                  return ;
        }*/
    
/*            const ids = this.$route.query.sensor_id.split(',');
            const start = moment(this.start_date); 
            const end = moment(this.start_date);
  */
      
            this.$emit('show-loader',false);  
            await new Promise(s => setTimeout(s, 200));
            await this.executeSql(); 
            this.$emit('show-loader',false);
            this.isShowSQL=false;
            window.document.addEventListener('click',this.outsideClickEvent );
  },
  
  methods:{
     copyURL(){
         try{ 
            
            const url = window.location.href;
            window.navigator.clipboard.writeText(url );
            this.$emit('show-flash',{"message":"クリップボードにURLをコピーしました。","type": "success"}); 
         
         }catch(e){

               this.$emit('show-flash',{"message":"お使いのブラウザは対応していません。","type": "warning"});  
         
         }
      },
      showSQL(){
          
        if(this.isShowSQL){ this.isShowSQL=false; }
        else{  this.isShowSQL = true; }
      },
      editorDidChange(text,obj){
        
        var parent = document.activeElement.parentElement; 
        
        while ( parent.id.indexOf( 'editor-' ) == -1 ){
             parent = parent.parentElement;
        }
        
         const i =Number( parent.id.replace( 'editor-', '' ));
          this.editorList[i].value= text;
        },
       outsideClickEvent ( e )  { 
          
              if(  e.target.nodeName.toLowerCase() !== "input" && e.target.className && e.target.className.indexOf( 'show-menu' ) ===  -1 ){  
              this.isShowFile =false ;
              this.isShowView= false ;
                }
          },
    importCsv( e ){
        if (e.target instanceof HTMLInputElement) {
       const file =  e.target.files[0];

       if ( ! file.type.match(/csv/) ){
           
             this.$emit('show-flash',{"message":"CSVファイルを選択して下さい。","type": "danger"});  
           return;
       }
       const reader  = new FileReader();
          
          reader.readAsText(file);
          reader.onload = () => {
                let lines = reader.result.split("\n");
                const cols = lines.shift();
                 for (let i = 0; i < lines.length; i++) {    
    
                     this.Sql.execute("  INSERT OR IGNORE INTO sensor_data ("+cols+") VALUES ( "+ lines[i] + "); ", 
    
                (res)=>{ 
                // console.log(res);
                });
    
                }
            }     
      }
    },// 上と下 平均値 と 真ん中の差分
    hideMenus( ){
         
        this.isShowFile = false;
        this.isShowView = false;
        this.isShowSearch = false;
      
    },
    showView( ){
          this.hideMenus();
          this.isShowView = true;
    },
    showFile( ){
        this.hideMenus();
        this.isShowFile =true;   
        
    },
     showSearch( ){
      
            this.hideMenus();
            this.isShowSearch =true;
      
      },
      downloadCsv( i ){
    
           const dataname =  this.chartData.series[i].name || this.$route.params.col ;
           if(this.chartData.series[i].data.length == 0){ 
                this.$emit('show-flash',{"message":"この項目にデータはありません。","type": "warning"});  
                return;
            }
           let fields= [ "年月日時" , dataname ];
          
           const json2csvParser = new Parser({ fields ,  withBOM: true});
           var csvdata = [];
           for ( var j =0 ; j <  this.chartData.series[i].data.length ; j++ ){
              
              const o =  { "年月日時" : this.chartData.xAxis.data[j] }
               o[dataname] =  this.chartData.series[i].data[j] ;
               csvdata.push(o);
           }
        
        
          const csv = json2csvParser.parse(csvdata) ;   
         
          var extention = "csv"
          var exportedFilenmae = (dataname  || 'export') + '.' + extention;
          var blob = new Blob([csv], { type: 'text/csv;charset=shift_jis;' });

          if ( window.navigator.msSaveBlob) { // for IE 10+
                  
            window.navigator.msSaveBlob(blob, exportedFilenmae);
          } else {

        //anchorを生成してclickイベントを呼び出す。
   
          var link = window.document.createElement("a");
          if (link.download !== undefined) {
           // const b = window.parent.confirm('確認ダイアログに表示する文言');
           
            var url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", exportedFilenmae);
            link.style.visibility = 'hidden';
        
            window.document.body.appendChild(link);
            link.click();
            window.document.body.removeChild(link);
    
            }
           
           }
     
      },
      downloadImage(){
        const link = window.document.createElement("a");
        const canvas = window.document.getElementsByTagName('canvas')[0];
        const ctx = canvas.getContext('2d');
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = 'white';
        ctx.fillRect(0, 0, canvas.width, canvas.height);
          link.href =  canvas.toDataURL('image/png') ;
        link.download = "screenshot.png";
        link.click();
    },
      addSqlField : function ( ){
         this. editorList.push({value:""});
      },
      async executeSql( ){
   
         if ( ! window.isCrossOrigin ){

             this.Sql = await  window.parent.getSql() ;   
      
       } else {

              this.Sql = await  window.getSql() ;   
      
        }
       
                this.sqlResults = [];
                this.executeList = [];
            
            var query = "";
              _.map(this.editorList,   (o)=>{
                      
                      query += o.value;
               }); 
               
                var q = query.replaceAll("{PARTICLE_SIZE}", this.particle_size );
                q = query.replaceAll("{TEMPORAL_GRANULARITY}", this.particle_size );
                q = q.replaceAll("{AGGREGATE}", this.aggregate );
                q = q.replaceAll("{START_DATE}", this.start_date );
                q = q.replaceAll("{END_DATE}",this.end_date); 
                q = q.replace("{TODAY}",  moment().format('YYYY-MM-DD') );   
                q = q.replace("{TODAY+1D}", moment().add('d',1).format('YYYY-MM-DD') );   
  
                try{
 
                  this.Sql.executeAsyncQueue( q , (res)=>{ 
                  
                    if(res ){
                           
                           for ( var r = res.pop() ; r ; r=res.pop() ){
                                
                                this.sqlResults.push(r);
                            }
                         } 
                  });

                  await this.Sql.executeAsync();
                  }catch (e){
                  
                      console.warn(e);
                  }
       
                 /*
                 const p = async ()=>{ return new Promise((resolve) => {
                        this.Sql.execute( q , (res)=>{ 
                        if(res ){
                        
                              for ( var r = res.pop() ; r ; r=res.pop() ){
                                this.sqlResults.push(r);
                            }
                         } 
                         resolve(true);
                        }) ;
                 }); };
                 await p();
                 */
                 //console.log( this.sqlResults);
                 
            const url = new URL( window.location );
            url.searchParams.set( 'q',query ) ; 
       
          if ( window.parent !== window && !window.isCrossOrigin ) { 
            if(this.isEditMode) {
 
                if ( false !== this.sq ){
              
                 window.parent.history.pushState( {}, '', new URL( window.parent.location.href).pathname + "?ec="+ await Compression.Compression.compressString(query) );  
                 
                }
            }
          }
          this.updateChart();
      },
      updateChart(){
          

                 for(var i=0 ; i < this.chartData.series.length; i++){ 
                
                    this.chartData.series[i].data = [];
                    this.chartData.series[i].name = "" ;
                }
                this.chartData.xAxis.data = [];

                 _.map(  this.sqlResults,  async (o)=>{  
                      //  await this.executeList[i];
                    if( this.chartData.xAxis.data.length < o.values.length ) {
                        
                      this.chartData.xAxis.data =  _.map( o.values , (value)=>{ return value[0]; });
                    }
                    
                    this.chartData.series.push( { 
                    name : o.columns[1],
                    type:"line" ,
                    data :  _.map( o.values ,(value)=>{ return value[1]; }), 
                    smooth: true,
                    connectNulls: true
                    }) 
            });
            
        
      }
   },
}

</script>
<style>

main {

  margin: 0 !important;
}

header,nav{
display: none !important;
}

main{
width: 100% !important ;
}

.container {
  width: 200px;
  margin: 30px;
}

.input {
  width: 100%;
  margin: 0px;
  padding-right: 5px;
}

.eye::after {
  font-family: 'FontAwesome';
  content: "\f06e";
}

.eye-slash::after {
  font-family: 'FontAwesome';
  content: "\f070";
}

.input-icon {
  position: relative;
  float: right;
  margin-top: -25px;
}
</style>